import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";


import BlancoBold from './assets/fonts/Blanco/Blanco-Bold.ttf'
import BlacoExtra from './assets/fonts/Blanco/Blanco-ExtraBold.ttf'
import CiudadelaRegular from './assets/fonts/Ciudadela/Ciudadela-Pro-Regular.otf'
import CiudadelaMedium from './assets/fonts/Ciudadela/Ciudadela-Pro-Medium.otf'
import CiudadelaBold from './assets/fonts/Ciudadela/Ciudadela-Pro-Bold.otf'
import CiudadelaBoldItalic from './assets/fonts/Ciudadela/Ciudadela-Pro-Bold-Italic.otf'
import CiudadelaMediumItalic from './assets/fonts/Ciudadela/Ciudadela-Pro-Medium-Italic.otf'


import loadable from '@loadable/component';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-221812915-1');
ReactGA.pageview(window.location.pathname + window.location.search);

const Map = loadable(() => import('./page/Map'));
const Memoria = loadable(() => import('./page/Memoria'));
const Conoce = loadable(() => import('./page/Conoce'));
const Contact = loadable(() => import('./page/Contact'));
const Mesa = loadable(() => import('./page/Mesa'));
const Theme = loadable(() => import('./theme'));

const App = () => {

  return (
    <Theme>
      <Router>
        <Routes>
          <Route exact path="/" element={<Map />} />
          <Route exact path="/acerca_de_alba" element={<Conoce />} />
          <Route exact path="/contacto" element={<Contact />} />
          <Route exact path="/mesa_de_trabajo" element={<Mesa />} />
          <Route path={"/memoria/:name"} element={<Memoria />} />
          <Route exact path={"/memoria/Prefectura_Nacional_Naval_DIPRE_/:name"} element={<Memoria />} />
        </Routes>
      </Router>
    </Theme>
  );
}

export default App;
